import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Address from '../types/address';
import WasteDetails, { KeyValuePair } from '../types/wasteDetails';
import WasteContainer from '../types/wasteContainer';
import { IQuoteFilters } from '../components/quote/QuoteFilters';
import { QuoteResult, Price, CalculatedPrice } from '../types/quote';
import { isEqual, omit } from 'lodash'
import { Quote } from '../components/quote/quotes';

export interface QuoteState {
    currentStep: number;
    quoteType: KeyValuePair | null;
    address: Address | null;
    wasteDetails: WasteDetails[];
    availableContainerOptions: WasteContainer[];
    generatedQuote: QuoteResult | null;
    filters: IQuoteFilters;
    selectedQuote: CalculatedPrice | null
}

const initialFilters = {
    weight: { value: "" }
}
  
const initialState: QuoteState = {
    currentStep: -1,
    quoteType: null,
    address: null,
    wasteDetails: [],
    availableContainerOptions: [],
    generatedQuote: null,
    filters: initialFilters,
    selectedQuote: null
}
  
export const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: {
        resetFilter: (state) => {
            state.filters = Object.assign({}, {...initialFilters})
        },
        updateFilter: (state, { payload }:PayloadAction<Partial<IQuoteFilters>>) => {
            state.filters = Object.assign({}, {...state.filters}, {...payload});
        },
        nextStep: (state) => {
            state.currentStep += 1;
        },
        previousStep: (state) => {
            state.currentStep -= 1;
        },
        setStep: (state, { payload }:PayloadAction<number>) => {
            state.currentStep = payload;
        },
        setQuoteType: (state, { payload }:PayloadAction<KeyValuePair>) => {
            state.quoteType = payload;
        },
        setAddress: (state, { payload }:PayloadAction<Address>) => {
            state.address = payload;
        },
        addWasteDetails: (state, { payload }:PayloadAction<WasteDetails>) => {
            state.wasteDetails.push(payload);
        },
        removeWasteDetails: (state, { payload }:PayloadAction<{wasteType: string, containerType: string}>) => {
            
            var index = findWasteTypeIndex(state.wasteDetails, payload.wasteType, payload.containerType);

            if(index >= 0) {
                state.wasteDetails.splice(index, 1);
            }
        },
        clearWasteDetails: (state) => {
            state.wasteDetails = initialState.wasteDetails;
        },
        setAvailableContainerOptions: (state, { payload }:PayloadAction<WasteContainer[]>) => {
            state.availableContainerOptions = payload;
        },
        setGeneratedQuote: (state, { payload }:PayloadAction<QuoteResult>) => {

            //Give each price a unqiue identifier and set as not accepted
            payload.exactMatches?.forEach((price:Price, index:number) => {
                price.id = index;
                price.accepted = isCheapest(payload, price);
            });

            //Set generated quote
            state.generatedQuote = payload;

        },
        acceptQuote: (state, { payload }:PayloadAction<{quote: CalculatedPrice}>) => {
            state.selectedQuote = payload.quote;
        },
        resetState: (state) => {
            state.currentStep = initialState.currentStep;
            state.quoteType = initialState.quoteType;
            state.address = initialState.address;
            state.wasteDetails = initialState.wasteDetails;
            state.availableContainerOptions = initialState.availableContainerOptions;
            state.generatedQuote = initialState.generatedQuote;
        }
    }
})

const findWasteTypeIndex = (array: WasteDetails[], wasteType: string, containerType: string) => {

    if(array != null) {
        return array.findIndex((item) => item.wasteType.key == wasteType && item.containerType.key == containerType, 0);
    }

    return -1;
}

const isCheapest = (result: QuoteResult, price:Price) => {

    var cheapestPrice = 0;

    result.exactMatches.forEach((item) => {

        //Check if current item is for the specified service and cheaper than the currently logged cheapest price
        if(item.containerType == price.containerType && item.wasteType == price.wasteType && (cheapestPrice == 0 || item.amount < cheapestPrice)) 
        {
            cheapestPrice = item.amount;
        }
    });

    return price.amount == cheapestPrice;
}

// Action creators are generated for each case reducer function
export const { 
    nextStep, 
    previousStep, 
    setStep,
    setQuoteType,
    setAddress, 
    addWasteDetails,
    removeWasteDetails, 
    clearWasteDetails,
    setAvailableContainerOptions,
    setGeneratedQuote,
    acceptQuote,
    resetState,
    resetFilter,
    updateFilter
 } = quoteSlice.actions
  
export default quoteSlice.reducer