import React from 'react';
import { store } from './src/store/store'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react';
import './src/styles/global.css';

export const wrapRootElement = ({element}) => {
    return (
        <Provider store={store}>
            <Auth0Provider 
                domain={process.env.GATSBY_AUTH_DOMAIN}
                clientId={process.env.GATSBY_AUTH_CLIENT_ID}
                redirectUri={window.location.origin}
                audience={process.env.GATSBY_AUTH_AUDIENCE}
                scope={process.env.GATSBY_AUTH_SCOPES}
                useRefreshTokens={true}>
                {element}
            </Auth0Provider>
        </Provider>
    );
}