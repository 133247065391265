import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
    show: boolean
}

const initialState: SidebarState = {
    show: false
};

export const sidebarNavSlice = createSlice({
    name: 'sidebarNav',
    initialState,
    reducers: {
        sidebarToggle: (state, { payload }:PayloadAction<boolean>) => {
            state.show = payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { sidebarToggle } = sidebarNavSlice.actions
  
export default sidebarNavSlice.reducer