import { configureStore } from '@reduxjs/toolkit';
import quoteSlice from './quoteSlice';
import sidebarNavSlice from './sidebarNavSlice';

export const store = configureStore({
    reducer: {
        sidebarNav: sidebarNavSlice,
        quote: quoteSlice
    }
  })
  
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch